import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const BlogAnniversaryPage =  () => {
    return (
        <>
        <Layout>
            <h1>10 Things I Learned from Making Our First Video Game Unleaving</h1>
            <StaticImage
                className="image"
                src="../assets/images/elephant-unleaving-video-game.jpg"
                alt="unleaving video game - anniversary cake"
                layout="fullWidth"
                placeholder="blurred"
                loading="eager"
            />
            <p>🍁 Today Marks the 1st Anniversary of Unleaving
            Released on March 27th, 2024, Unleaving was more than just a game, it was a labor of love, obsession, and raw creativity. Reflecting on this journey, I want to share 10 things I learned from making our first game:</p>
            <ol>
                <li><b>It’s going to take away all your attention:</b> Making a game consumes you, it seeps into your thoughts, dreams, and every quiet moment. And that’s okay.</li>
                <li><b>Make it as if no one cares, but you care</b>
                Create with reckless passion, as if the world won’t notice, because you’re the one who needs to.</li>
                <li><b>Surround yourself with support</b>
                You’ll need people who believe in you when you’re doubting yourself. Their support will carry you through the hardest days.</li>
                <li>Listen to your community, playtesters
                Your audience sees things you can’t. Their feedback reveals flaws and magic you might overlook.</li>
                <li>Every pixel matters, obsessing over art is worth it
                The tiniest brushstroke or color shift carries emotion. The details add up to something meaningful.</li>
                <li>That obsession with bug fixing is worth it
                Squashing every bug, even the sneaky ones no one might notice, is what makes your game feel polished and whole.</li>
                <li> Love what you do, and do it anyway
                On the good days, your passion fuels you. On the hard days, your discipline keeps you going.</li>
                <li> It’s okay to hate it sometimes, do it anyway
                Some days you’ll despise your own creation. Keep going. The frustration is part of the process.</li>
                <li>Make the game you want to play
                When you create a game you’d love as a player, you’ll finish it. And that makes it worth it.</li>
                <li> It’s going to matter in the end
                Even if it sometimes feels like no one sees the struggle, what you create will leave a mark on players, on the industry, and on you.</li>
            </ol>
            <StaticImage
                className="image"
                src="../assets/images/unleaving-cake-anniversary.jpg"
                alt="unleaving video game - anniversary cake"
                layout="fullWidth"
                placeholder="blurred"
                loading="eager"
            />
        </Layout>
        </>
    )
}

export default BlogAnniversaryPage